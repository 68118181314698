<template>
    <div class="card-ticket">
        <div class="row">
            <div class="xs12 pb-2 pa-3">
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-calendar-clock"></i>
                    </div>
                    <div :class="textRight">
                        {{ calculateNgayTrongTuan }},
                        Ngày {{$Helper.formatDate(item.NgayDuong)}}
                    </div>
                </div>
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-calendar-clock"></i>
                    </div>
                    <div :class="textRight">
                        Lịch âm: {{item.NgayAm.Day}}/{{item.NgayAm.Month}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DxButton } from "devextreme-vue";
export default {
    components: {
        DxButton,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            rowText: "row mb-1 align-center row-text",
            rowLastText: "row align-center row-text",
            textRight: " font-medium",
        };
    },
    computed: {
        calculateNgayTrongTuan() {
            return this.item.NgayTrongTuan == 0
                ? "Chủ nhật"
                : "Thứ " + (this.item.NgayTrongTuan + 1);
        },
    },
    methods: {},
    created() {
    }
};
</script>

<style scoped>
.card-ticket {
    position: relative;
    box-shadow: 0px 2px 9px #ccc;
    overflow: hidden;
}
.row-text {
    word-break: break-word;
    white-space: break-spaces;
}
</style>